import React from "react";
import { TweetsComponent, HeaderComponent} from "./tweets";

function App() {
  
    return (
      <div className="container-fluid">
        <HeaderComponent/>
        <div className="container margin-top">
          <h5><strong>Home</strong></h5>
          <TweetsComponent/>
        </div>
      </div>
    );
}

export default App;
