import React, {useState, useEffect} from "react";
import { apiTweetList, apiTweetDetail, apiCreateTweet, apiTweetLike, apiTweetFeed } from "../lookup";

export function HeaderComponent (){
  return <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
  <div className="container">
 
    <a className="navbar-brand" href="/">
      <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh4zu2rGA8O90sKDRweHk7XogaXttx9-uEfQ&usqp=CAU" className="logo" alt="img"/>PyTweet
    </a>
    
     
    
   
        <ul className="navbar-nav  ml-auto navbar-icons d-flex flex-row">
       
    
        <li className="nav-item ">
          <span className="nav-link"><i className="fa fa-home fa-lg"></i></span>
          </li>
        
      </ul>
    
    
  </div>
</nav>
}

export function TweetsComponent(props){
  const textAreaRef = React.createRef()
  const tagRef = React.createRef()
  const [newTweets, setNewTweets] = useState([])
  const canTweet = props.canTweet === "false" ? false :true
  const handleNewTweetSubmit = (e) =>{
      e.preventDefault()
      const newVal = textAreaRef.current.value
      const tagVal = tagRef.current.value
      const newTweet = {
        content : newVal,
        tags : tagVal
      }


      let tempNewTweets = [...newTweets]
      console.log(newVal, tagVal)
      //apiCreateTweet(newVal,(response, status)=>{
      apiCreateTweet(newTweet,(response, status)=>{
        if (status===201) {
          tempNewTweets.unshift(response)
          setNewTweets(tempNewTweets)
        } else {
          console.log("ERROR", response)
          alert("Auth error")
        }
        
      })
     
     
      textAreaRef.current.value=''
      tagRef.current.value=''
  }
  return <div>
    { canTweet ?
      <form onSubmit={handleNewTweetSubmit} className="tweet-form">
      <textarea ref={textAreaRef} required name="tweet" className='form-control' placeholder='Whats on your mind'></textarea>
      <input ref={tagRef} required_name="tags" id='input-tag' placeholder="Tags"/>
      <button type='submit' className='btn btn-primary pull-right'>Tweet</button>
  </form> : null }
  <TweetsList newTweets={newTweets} {...props}/>
  </div>
}


export function TweetsList(props){
  const [tweetsInit, setTweetsInit] = useState([]);
  const [tweets, setTweets] = useState([])
  const [tweetsDidSet, setTweetsDidSet] = useState(false)
   useEffect(()=>{
    const final = [...props.newTweets].concat(tweetsInit)
    if (final.length !== tweets.length){
      setTweets(final)
    }
   }, [props.newTweets, tweets, tweetsInit])

  useEffect(()=>{
    if (tweetsDidSet === false){
    const callBack = (response, status) => {
      if (status === 200) {
      setTweetsInit(response)
      setTweetsDidSet(true)
     } //else {
    //   alert("error")
    // }
  }
    apiTweetList(props.username, callBack)
 }
  }, [tweetsInit, tweetsDidSet, setTweetsDidSet, props.username])
  
  return  <div id="list-wrapper">
  {tweets.map(function(item, index){
    return <Tweet tweet={item} key={index}/>
  })}
</div>
}

export function FeedComponent(props){
  const [newTweets] = useState([])
  //const canTweet = props.canTweet === "false" ? false :true
  
  return <div>
   <TweetsFeed newTweets={newTweets} {...props}/>
  </div>
}

export function TweetsFeed(props){
  const [tweetsInit, setTweetsInit] = useState([]);
   const [tweets, setTweets] = useState([])
  const [tweetsDidSet, setTweetsDidSet] = useState(false)
   useEffect(()=>{
    const final = [...props.newTweets].concat(tweetsInit)
    if (final.length !== tweets.length){
      setTweets(final)
    }
   }, [props.newTweets, tweets, tweetsInit])

  useEffect(()=>{
    if (tweetsDidSet === false){
    const callBack = (response, status) => {
      if (status === 200) {
      setTweetsInit(response)
      setTweetsDidSet(true)
    }
  }
    apiTweetFeed(props.username, callBack)
 }
  }, [tweetsInit, tweetsDidSet, setTweetsDidSet, props.username])
  
  return  <div id="list-wrapper">
  {tweets.map(function(item, index){
    return <Tweet tweet={item} key={index}/>
  })}
</div>
}

export function TweetDetailComponent(props){
  const {tweetId} = props
  const [didLookup, setDidLookup] = useState(false)
  const [tweet, setTweet] = useState(null)

  const handleActionBackendLookup = (response, status) => {
    if (status===200){
      setTweet(response)
    } //else {
    //  alert("There was an error")
    //}
  }
  const handleLinkHome = (e) =>{
    e.preventDefault()
    window.location.href = "/"
  }
  useEffect(()=>{
    if (didLookup === false){
      apiTweetDetail(tweetId, handleActionBackendLookup)
      setDidLookup(true)
    }
  }, [didLookup, setDidLookup, tweetId])
  return tweet === null ? null :
   <div>
     <a href="/" onClick={handleLinkHome} className="link-back">
       <h6><i className="fa fa-angle-left"></i>- Tweet</h6> </a><br/> 
       <Tweet tweet={tweet}/>
    </div>
}
 
export function Tweet(props){
  const {tweet} = props
  const [actionTweet, setActionTweet] = useState(props.tweet ? props.tweet : null)
  const isDetail = false

  const handlePerformAction = (newActionTweet, status) => {
      setActionTweet(newActionTweet)
  }
  const handleLinkTweet = (e)=>{
    e.preventDefault()
    window.location.href = `/${tweet.id}`
  }

  const handleLinkUser= (e)=>{
    e.preventDefault()
    window.location.href = `/profile/detail/${tweet.user.username}`
  }
  return <div className='col-12 border py-3 tweetItem'>
      <p><strong><a href="/" onClick={handleLinkUser} >{ tweet.user.username}</a> </strong>{formatDate(tweet.created)}</p>
      {isDetail === false ?
        <h6><a href="/" onClick={handleLinkTweet} className="tweet-item">{tweet.content} </a> 
        <a href="/">#{tweet.tags} </a></h6>: <h6>{tweet.content} 
        <a href="/">#{tweet.tags} </a></h6>}

      <a href='/' className='btn btn-light btn-sm commentBtn'>
        <i className='fa fa-comment-o'></i></a> 
          <span className="likefnt">0</span>

      {actionTweet && <span>
      <ActionBtn tweet={actionTweet} didPerformAction={handlePerformAction} action={{type:"like", display:"Likes"}}/>
      <ActionBtn tweet={actionTweet}  didPerformAction={handlePerformAction} action={{type:"unlike", display:"Unlike" }}/>
      </span>}
      </div>
}


export function ActionBtn(props){
  const {tweet, action, didPerformAction} = props
  const likes = tweet.likes ? tweet.likes : 0
  //const actionDisplay = action.display ? action.display : 'Action'
  
  const handleActionBackendEvent = (response, status)=>{
    console.log(response, status)
    if ((status === 200 || status === 201) && didPerformAction){
      didPerformAction(response, status) 
    }
  }
  
  const handleClicked = (e) => {
      e.preventDefault()
      console.log(tweet.id, action.type)
      apiTweetLike(tweet.id, action.type, handleActionBackendEvent )
    
  }
   const className = 'btn btn-light btn-sm likeBtn'
   const display =  action.type ==='like' ? ` ${likes}` : ` ${likes}`

   return action.display === "Likes" ? <button className={className} onClick={handleClicked}><i className="fa fa-heart-o"></i> {display}</button> 
   : <button className={className} onClick={handleClicked}><i className="fa fa-heart"></i> {display}</button>   
}

function formatDate (dte){
   var strDte = dte
  
   return strDte
  }
  

  
