function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function lookup(method, endpoint, callback, data){
    let jsonData;
    if (data){
        jsonData = JSON.stringify(data)   
    }
    const url = `http://localhost:8000/api${endpoint}`
    console.log('API url', url)
    const xhr  = new XMLHttpRequest()
    xhr.responseType = "json"
    const csrftoken = getCookie('csrftoken')
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    if (csrftoken){
        xhr.setRequestHeader("HTTP_X_REQUESTED_WITH","XMLHttpRequest")
        xhr.setRequestHeader("X-Requested-With","XMLHttpRequest")
        xhr.setRequestHeader("X-CSRFToken", csrftoken )
    }

    xhr.onload = function() {
        if (xhr.status === 403){
            const detail = xhr.response.detail
            if (detail === "Authentication credentials were not provided."){
                if (window.location.href.indedOf("login") === -1){
                    window.location.href = "/signin?showLoginRequred=true"
                }
            }
        }
      callback(xhr.response, xhr.status)
    }

    xhr.onerror = function(e){
        callback({"Message":"The request was an error"},400)
      }
    xhr.send(jsonData)
}

  
export function apiTweetList(username, callback) {
    let endpoint = "/tweets"
    if (username){
        endpoint = `/tweets?username=${username}`
    }
    lookup("GET", endpoint, callback)
}

export function apiTweetDetail(tweetId, callback) {
    lookup("GET", `/tweets/${tweetId}`, callback)
}

  
export function apiCreateTweet(newTweet, callback){
    //lookup("POST", "create-tweet", callback, {content:newTweet})
    lookup("POST", "/tweets/create-tweet", callback, newTweet)
  }

  export function apiTweetLike(tweetId, action, callback) {
    lookup("POST", "/tweets/like", callback, {id:tweetId, action:action})
  }

  export function apiTweetFeed(username, callback) {
    let endpoint = "/tweets/feed"
    if (username){
        endpoint = `/tweets?username=${username}`
    }
    lookup("GET", endpoint, callback)
}


//******************** Profile */

export function apiProfileDetail(username, callback){
   lookup("GET", `/profile/${username}`, callback)
}